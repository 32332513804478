$accent: #4d9391;
$primary: #395692;
$warn: #af2b2b;

// Solid color definitions
$primary-background: #395692;
$primary-background-transparent-50: rgba(50, 74, 108, 0.5);
$primary-red: #ed3224;
$primary-dark-red: #8b1c14;
$primary-dark-gray: #666666;
$primary-light-gray: #f1f1f1;
$primary-white: white;
$primary-ghost-white: #f7f7fb;
$primary-dark-blue: #212745;
$primary-black: #212529;

// Alert pallete definitions
$alert-success: #35d39d;
$alert-success-light: #ebfbf5;
$alert-info: #03d0ea;
$alert-info-light: #e5fafd;
$alert-error: #ef4d62;
$alert-error-light: #fdedef;

// Gradient definitions
$primary-dark-blue-gradient: linear-gradient(
  124.42deg,
  #070a17 -1.19%,
  #191f38 33.47%,
  #232a4a 58.25%,
  #232a4a 70.27%,
  #1c223e 100%
);
$accent-green-gradient: linear-gradient(72.43deg, #4d9391 9.73%, #72b5b3 91.4%);