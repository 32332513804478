@import './colors.scss';

// Accent:
$button-accent: $accent 0% 0% no-repeat padding-box;
$button-accent-border: 2px solid $accent !important;
$button-accent-gradient: transparent
  linear-gradient(254deg, #72b5b3 0%, $accent 100%) 0% 0% no-repeat padding-box;
// Primary:
$button-primary: $primary 0% 0% no-repeat padding-box;
$button-primary-border: 2px solid $primary !important;
$button-primary-gradient: transparent
  linear-gradient(180deg, #213153 0%, $primary 100%) 0% 0% no-repeat padding-box;

// Warn:
$button-warn: $warn 0% 0% no-repeat padding-box;
$button-warn-border: 2px solid $warn !important;
$button-warn-gradient: transparent
  linear-gradient(243deg, $warn 0%, #e03131 100%) 0% 0% no-repeat padding-box;

$button-box-shadow: 20px 20px 60px #00000066;

/* Quote: https://material.angular.io/components/button/overview#capitalization
 *
 * According to the Material design spec button text has to be capitalized
 */
 .mdc-button, 
 .mat-mdc-button-base {
   --mdc-typography-button-text-transform: uppercase;
   text-transform: uppercase;
 }
 
 // Button sizes for normal buttons
 $pally-button-height: (
   l: 42px,
   m: 36px,
   s: 30px,
 );
 
 // Button sizes for rounded buttons
 $pally-rounded-button-height: (
   l: 46px,
   m: 36px,
   s: 34px,
 );
 
 // Button sizes for icon only buttons
 $pally-icon-button-height: (
   l: 48px,
   m: 36px,
   s: 36px,
 );
 
 // Apply margin to icons inside the button. Default to icons on the left side.
 // There is no css to handle text nodes, so we can't easily auto style
 // icons on the right side. The solution is to add a class for that
 @mixin pally-button-icon-margin {
   // Apply margin to icons inside the button. Default to icons on the left side.
   // There is no css to handle text nodes, so we can't easily auto style
   // icons on the right side. The solution is to add a class for that
   mat-icon {
     margin-right: 8px;
     width: 24px !important;
   }
   mat-icon[end] {
     margin-right: 0px; // Reset the default
     margin-left: 8px;
   }
 }
 
 ///////////////////////////////////
 // Generate normal button styles //
 ///////////////////////////////////
 
 // Mixin to create the normal style
 @mixin pally-button($height) {
   height: $height;
   line-height: $height;
 }
 
 // Create one for each style
 @each $name, $height in $pally-button-height {
   // We do double selector to increase the specificity slighly above normal mat-button styling
   [pally-button-#{$name}][pally-button-#{$name}] {
     @include pally-button($height);
     @include pally-button-icon-margin;
   }
 }
 
 ////////////////////////////////////
 // Generate rounded button styles //
 ////////////////////////////////////
 
 // Mixin to create the rounded style
 @mixin pally-rounded-button($height) {
   height: $height;
   line-height: $height;
   border-radius: $height * 0.5;
 }
 
 // Create one for each style
 @each $name, $height in $pally-rounded-button-height {
   // We do double selector to increase the specificity slighly above normal mat-button styling
   [pally-rounded-button-#{$name}][pally-rounded-button-#{$name}] {
     @include pally-rounded-button($height);
     @include pally-button-icon-margin;
   }
 }
 
 ////////////////////////////////////////
 // Generate icon (only) button styles //
 ////////////////////////////////////////
 
 // Mixin to create the icon style
 @mixin pally-icon-button($height) {
   height: $height;
   width: $height;
   line-height: $height;
   border-radius: $height * 0.5;
   mat-icon {
     margin-right: 0;
     height: calc($height / 2) !important;
     width: $height !important;
     position: absolute;
     margin-left: 0;
   }
 
   // We need to specify the min with to override the default min-width of material button
   min-width: $height;
 
   // Remove padding. The size is fixed, and we want the icon to get placed in the center
   padding: 0;
 }
 
 // Create one for each style
 @each $name, $height in $pally-icon-button-height {
   // We do double selector to increase the specificity slighly above normal mat-button styling
   [pally-icon-button-#{$name}][pally-icon-button-#{$name}] {
     @include pally-icon-button($height);
   }
 }
 
 ////////////////////////////////////
 // Hollow button style.           //
 ////////////////////////////////////
 
 $pally-button-color: (
   'blue': #395692,
   'teal': #4d9391,
   'red': #af2b2b,
 );
 
 // Mixin to create the hollow style
 @mixin pally-hollow-button($color) {
   color: $color !important;
   background: none !important;
   box-shadow: none !important;
   border: 1px solid $color;
   &:disabled {
     opacity: 0.3 !important;
   }
 }
 
 // Create one for each color
 @each $name, $color in $pally-button-color {
   // We do double selector to increase the specificity slighly above normal mat-button styling
   [pally-hollow-button-#{$name}][pally-hollow-button-#{$name}] {
     @include pally-hollow-button($color);
   }
 }
 