[pally-gradient][color='primary'],
[pally-gradient='primary'] {
  background: linear-gradient(180deg, #123075 0%, #395692 100%);
}

[pally-gradient][color='accent'],
[pally-gradient='accent'] {
  background: linear-gradient(90deg, #4d9391 0%, #72b5b3 100%);
}

[pally-gradient][color='warn'],
[pally-gradient='warn'] {
  background: linear-gradient(90deg, #e03131 0%, #af2b2b 100%);
}
